export default interface IPagination {
    page: number;
    limit: number;
    total?: number;
    filter?: string;
}

export function toQueryString(p: IPagination): string {
    const f = !!p.filter && p.filter != '' ? `&filter=${p.filter}` : ''
    return `?page=${p.page}&limit=${p.limit}` + f
}