import { Navigate } from "react-router-dom";
import ClientList from "@/pages/client/client_list";
import ClientPage from "@/pages/client/client_page";
import ClientOverview from "@/pages/client/sub_pages/overview";
import ClientSecurity from "@/pages/client/sub_pages/security";


const clientRoutes = {
    path: "client",
    children: [
        {
        index: true,
        element: <Navigate to="/client/list" replace />,
        },
        {
        path: "list",
        element: <ClientList />
        },
        {
        path: ":id",
        element: <ClientPage />,
        children: [
            {
            index: true,
            element: <Navigate to="/client/:id/overview" replace />,
            },
            {
            path: "overview",
            element: <ClientOverview />
            },
            {
            path: "security",
            element: <ClientSecurity />
            },
        ]
        }
    ]
    };

export default clientRoutes;