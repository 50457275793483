import { useTranslation } from "react-i18next";
import { NavItem } from "@/interfaces/components/nav_item";


const useClientMenuItems = (id: string): Array<NavItem> => {

    const { t } = useTranslation();

    const MenuItems: Array<NavItem> = [
        {
            "label": t("components.clientMenu.overview"), 
            "path": `/client/${id}/overview`
        },
        {
            "label": t("components.clientMenu.security"),
            "path": `/client/${id}/security`
        }
    ]

    return MenuItems;
}

export default useClientMenuItems;