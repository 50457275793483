import { z } from "zod";
import ruleset from "@/_helpers/ruleset";


export interface IUserForm {
    first_name: string;
    last_name: string;
    mobile: string;
    email: string;
}

export const userFormSchema = z.object({
    first_name: ruleset.name,
    last_name: ruleset.name,
    mobile: ruleset.phoneNumber,
    email: ruleset.email,
});