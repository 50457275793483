import { FC } from "react";
import { t } from "i18next";
import { useFormContext } from "react-hook-form";

// Services and interfaces
import { IUserForm } from "@/interfaces/user/user_form";

// Styles
import { ArrowRight } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import Button from "@/components/button";
import TextInput from "@/components/text_input";
import EmailInput from "@/components/email_input";
import PhoneInput from "@/components/phone_input";


interface _UserFormProps {
    isLoading?: boolean;
    onSubmit: () => void;
}

const UserForm: FC<_UserFormProps> = ({
    isLoading = false,
    onSubmit
}) => {

    const { control, formState } = useFormContext<IUserForm>();

    return (
        <Box display="flex" flexDirection="column" flexGrow={1} maxWidth="540px" alignItems="center" justifyContent="center">

            {/* Form */}
            <TextInput
                name='first_name'
                control={control}
                label={t('inputs.titles.firstName')}
                placeHolder="Ben"
                tabIndex={1}
                maxLength={50}
                />
            <TextInput
                name='last_name'
                control={control}
                label={t('inputs.titles.lastName')}
                placeHolder="Dover"
                tabIndex={2}
                maxLength={50}
                />

            <PhoneInput
                name='mobile'
                control={control}
                label={t('inputs.titles.phoneNumber')}
                placeHolder={t('inputs.placeholders.phoneNumber')}
                />
            <EmailInput
                name='email'
                control={control}
                label={t('inputs.titles.emailAddress')} 
                placeHolder="ben@1fit.com"
                tabIndex={4}
                />

            {/* Actions */}
            <Box display="flex" flexGrow={1} width="100%" justifyContent="flex-end">
                <Button
                    label={t('components.buttons.save')}
                    endIcon={<ArrowRight />}
                    loading={isLoading}
                    disabled={!formState.isValid || !formState.isDirty}
                    onClick={onSubmit}
                    />                        
            </Box>

        </Box>
    )
}

export default UserForm;