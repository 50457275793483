import * as Sentry from "@sentry/react";
import { Outlet } from "react-router-dom";
import { useEffect, useState } from "react";

// Config
import useNavigationItems from "@/_configs/navigation";

// Styles
import styles from './default.module.scss'
import { SidePanelOpen, SidePanelClose } from "@carbon/icons-react";

// Components
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import ListItem from "@mui/material/ListItem";
import Collapse from "@mui/material/Collapse";

import Tooltip from "@/components/tooltip";
import ListItemButton from "@mui/material/ListItemButton";
import NavButton from "@/components/navigation/nav_button";
import NavSideMenu from "@/components/navigation/side_menu";
import NavToolbar from "@/components/navigation/nav_toolbar";
import AppUpdatedModal from "@/components/app_updated_modal";


export default function DefaultLayout() {

    const navItems = useNavigationItems();
    const [listExpanded, setListExpanded] = useState<string|null>(null);
    // const [open, setOpen] = useState<boolean>(false);
    const [open, setOpen] = useState(() => {
        const storedValue = localStorage.getItem('navOpen');
        if (storedValue === null) return true;
        return storedValue === 'true';
    });
    
    const toggleForceOpen = () => setOpen(!open);
    const drawerWidth = open ? 256 : 56;

    useEffect(() => {
        localStorage.setItem('navOpen', String(open));
    }, [open]);

    // set user context
    Sentry.setContext('device', {
        'screen_resolution': `${window.screen.availWidth}x${window.screen.availHeight}`,
        'screen_height_pixels': window.screen.availHeight,
        'screen_width_pixels': window.screen.availWidth
    });

    return (
        <>
            <NavToolbar />
            <Drawer
                variant="permanent"
                className={styles.navdrawer}
                PaperProps={{sx: (theme) => ({
                    zIndex: theme.zIndex.drawer +1,
                    bgcolor: 'var(--nav-background)',
                    boxSizing: 'border-box',
                    width: drawerWidth,
                    overflow: 'hidden',
                    transition: 'width 0.3s ease-in'
                })}}
            >
                <Toolbar variant='dense' />
                <Box overflow='hidden'>
                    <List dense sx={{py: 1, px: 0.5}}>
                    {navItems.map(i => ( 
                        <Box key={i.path}>
                            {!open ? <Tooltip kind="nav" size='medium' placement='right' key={i.path} title={i.label}>
                                <Box>
                                    <NavButton
                                        label={i.label} 
                                        path={i.path} 
                                        startIcon={i.startIcon} 
                                        tabsRoot={i.tabsRoot}
                                        hasSubMenu={i.children !== undefined} 
                                        isOpen={listExpanded === i.label} 
                                        onClick={() => setListExpanded(listExpanded !== i.label && i.children ? i.label : null)}
                                        marginBottom="8px"
                                    />
                                </Box>
                            </Tooltip> :
                            <NavButton
                                label={i.label} 
                                path={i.path} 
                                startIcon={i.startIcon} 
                                tabsRoot={i.tabsRoot}
                                hasSubMenu={i.children !== undefined} 
                                isOpen={listExpanded === i.label} 
                                onClick={() => setListExpanded(listExpanded !== i.label && i.children ? i.label : null)}
                                marginBottom="8px"
                            />}
                            {/* sub nav items */}
                            {i.children && 
                                <Collapse in={listExpanded === i.label && open}>
                                    <List dense sx={{ml:2, py: 0}}>
                                    {i.children.map(c => (
                                        // TODO this needs fixing
                                        <NavButton 
                                            key={c.path} 
                                            label={c.label} 
                                            path={c.path} 
                                            startIcon={c.startIcon}
                                            tabsRoot={c.tabsRoot}
                                            kind="submenu" marginBottom="8px"
                                        />
                                    ))}
                                    </List>
                                </Collapse>
                            }
                        </Box>
                    ))}
                    </List>
                </Box>
                <Box flexGrow={1} />
                <Box display='flex'>
                    <Box flexGrow={1} />    
                    <ListItem sx={{width: 'unset', px: 0.5}}>
                        <ListItemButton sx={{height: 42}} onClick={toggleForceOpen}>
                            {open ? 
                                <SidePanelClose color='var(--nav-text-primary)' /> : 
                                <SidePanelOpen color='var(--nav-text-primary)' />
                            }
                        </ListItemButton>
                    </ListItem>
                </Box>
            </Drawer>
            {/* other drawer */}
            <Drawer
                open={!open && !!listExpanded}
                hideBackdrop
                PaperProps={{sx: {
                    flexDirection: 'row',
                    bgcolor: 'var(--nav-layer-01)',
                    boxSizing: 'border-box',
                    overflow: 'hidden',
                },
                onMouseLeave: () => setListExpanded(null)
            }}
            >
                <Box minWidth={drawerWidth}/>
                <Box>
                    <Toolbar variant='dense' />
                    <NavSideMenu 
                        label={navItems.find(c => c.label === listExpanded)?.label}
                        items={navItems.find(c => c.label === listExpanded)?.children ?? []}
                    />
                </Box>
            </Drawer>
            {/* main */}
            <Toolbar variant='dense' />
            <Box 
                display='flex'
                component='main'
                bgcolor='var(--nav-background)'
            >
                <Box minWidth={drawerWidth} sx={{
                    transition: 'min-width 0.3s ease-in'
                }} />
                <Box 
                    position="relative"
                    bgcolor='var(--background)'
                    overflow='hidden'
                    width='100%'
                    minHeight='calc(100vh - 40px)'
                    sx={{borderTopLeftRadius: 10}}
                    >
                    <Outlet />
                </Box>
            </Box>

            <AppUpdatedModal />
        </>
    );
}