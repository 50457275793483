import { t } from "i18next";
import { FC, useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { IUserForm, userFormSchema } from "@/interfaces/user/user_form";
import { useLazyGetClientQuery, useResendClientOnboardingMutation, useResetClientOnboardingMutation, useUpdateClientMutation, useVerifyClientMutation } from "@/repositories/client";

// Components
import { TagProps } from "@/components/tag";
import { Box, Container } from "@mui/material";
import UserForm from "@/pages/common/user_form";
import SecondaryToolbar from "@/components/secondary_toolbar";


const ClientOverview: FC = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const [getClient, { data: client }] = useLazyGetClientQuery();
    const [update, { isLoading: updateLoading }] = useUpdateClientMutation();
    const [verify, { isLoading: verifyLoading }] = useVerifyClientMutation();
    const [reset, { isLoading: resetLoading }] = useResetClientOnboardingMutation();
    const [resend, { isLoading: resendLoading }] = useResendClientOnboardingMutation();

    const isOnboarded = client?.onboarded_at != null;
    const isVerified = client?.user_meta.email_verified_at != null;
    const tags: TagProps[] = [
        { label: t('components.tag.labels.verified', {context: `${isVerified}`}), outlined: true, colour: isVerified ? 'teal' : 'red' },
        { label: t('components.tag.labels.onboarded', {context: `${isOnboarded}`}), outlined: true, colour: isOnboarded ? 'teal' : 'red' }
    ];
    
    const formMethods = useForm<IUserForm>({
        resolver: zodResolver(userFormSchema),
        mode: 'onBlur',
        defaultValues: {
            first_name: client?.user_meta.first_name ?? '',
            last_name: client?.user_meta.last_name ?? '',
            mobile: client?.user_meta.mobile ?? '',
            email: client?.user_meta.email ?? '',
        }
    });

    useEffect(() => {
        if (id) {
            getClient(id).unwrap().then((c) => {
                formMethods.reset({
                    first_name: c.user_meta.first_name,
                    last_name: c.user_meta.last_name,
                    mobile: c.user_meta.mobile,
                    email: c.user_meta.email,
                })
            }).catch((error: ApiError) => {
                navigate(`/client/list`);
                showToast({type: 'error', apiError: error.type})
            });
        }
    }, [id, formMethods, getClient, navigate,])

    const handleUpdate = (data: IUserForm) => {
        if (!id) return;
        update({id, data}).unwrap().then((coach) => {
            showToast({
                type: 'success', 
                title: t('notifications.coach.updated.title'), 
                message: t('notifications.coach.updated.message', {name: coach.full_name})
            });
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    const handleVerify = () => {
        if (!id) return;
        verify(id).unwrap().then((client) => {
            showToast({
                type: 'success', 
                title: t('notifications.client.verified.title'), 
                message: t('notifications.client.verified.message', {name: client.full_name})
            });
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    const handleReset = () => {
        if (!id) return;
        reset(id).unwrap().then((client) => {
            showToast({
                type: 'success', 
                title: t('notifications.client.resetOnboarded.title'), 
                message: t('notifications.client.resetOnboarded.message', {name: client.full_name})
            });
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    const handleResend = () => {
        if (!id) return;
        resend(id).unwrap().then((client) => {
            showToast({
                type: 'success', 
                title: t('notifications.client.resentOnboarding.title'), 
                message: t('notifications.client.resentOnboarding.message', {name: client.full_name})
            });
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    return (
        <Box width='100%' height='100%'>

            <SecondaryToolbar
                kind="primary"
                title={t('pages.client.headers.overview')}
                tags={tags}
                action1={!isVerified ? {
                    label: t('components.buttons.resendOnboarding'),
                    loading: verifyLoading,
                    disabled: resendLoading || resetLoading,
                    onClick: handleResend,
                } : undefined}
                action2={isOnboarded ? {
                    label: t('components.buttons.resetOnboarded'),
                    loading: resetLoading,
                    disabled: verifyLoading || resendLoading,
                    onClick: handleReset,
                } : undefined}
                action3={!isVerified ? {
                    label: t('components.buttons.verify'),
                    loading: resendLoading,
                    disabled: verifyLoading || resetLoading,
                    onClick: handleVerify,
                } : undefined}
            />

            {/* Main content */}
            <Box sx={{overflowY: 'auto', height: 'calc(100% - 48px)'}}>
                <Container sx={{py: '24px'}}>

                    <FormProvider {...formMethods}>
                        <UserForm
                            isLoading={updateLoading}
                            onSubmit={formMethods.handleSubmit(handleUpdate)}
                            />
                    </FormProvider>

                </Container>
            </Box>
            
        </Box>
    )

}

export default ClientOverview;