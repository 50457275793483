import baseApi from '@/repositories/base_api';

// Interfaces
import { IUser } from '@/interfaces/user/user';
import { IAPIResponse } from '@/interfaces/api/response';
import { ILoginCredentials } from '@/interfaces/auth/login_credentials';

import * as Sentry from "@sentry/react";

export const authApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getCsrfToken: build.query<void, void>({
      query: () => ({
        url: 'sanctum/csrf-cookie',
        method: 'GET',
        baseUrl: import.meta.env.VITE_BASE_URL,
      }),
    }),
    login: build.mutation<Promise<void>, ILoginCredentials>({
      query: (credentials) => ({
          url: 'auth/login',
          method: 'POST',
          data: credentials,
      }),
    }),
    logout: build.mutation<Promise<void>, void>({
      query: () => ({ url: 'auth/logout', method: 'POST' }),
      async onQueryStarted(_, { queryFulfilled }) {
        queryFulfilled.then(() => 
          Sentry.setUser(null)).catch(() => {})
      },
    }),
    getUser: build.query<IUser, void>({
      query: () => ({ url: 'auth/user', method: 'GET' }),
      transformResponse: (response: IAPIResponse<IUser>) => response.data,
      async onQueryStarted(_, { queryFulfilled }) {
        queryFulfilled.then((response) => Sentry.setUser({
          id: response.data.id ?? undefined,
          email: response.data.email,
        })).catch(() => {})
      },
    }),
  }),
})

export const { 
    useGetCsrfTokenQuery,
    useLoginMutation, 
    useLogoutMutation, 
    useGetUserQuery,
    useLazyGetUserQuery,
} = authApi;