import { useTranslation } from "react-i18next";
import { NavItem } from "@/interfaces/components/nav_item";


const useCoachMenuItems = (id: string): Array<NavItem> => {

    const { t } = useTranslation();

    const MenuItems: Array<NavItem> = [
        {
            "label": t("components.coachMenu.overview"), 
            "path": `/coach/${id}/overview`
        },
        {
            "label": t("components.coachMenu.security"),
            "path": `/coach/${id}/security`
        }
    ]

    return MenuItems;
}

export default useCoachMenuItems;