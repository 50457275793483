import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

// Helpers
import { capitalize } from "@/_helpers/text_functions";

// Services and interfaces
import { IClient } from "@/interfaces/client/client";

// Components
import { Box } from "@mui/material";
import ClientMenu from "./client_menu";
import Avatar from "@/components/avatar";
import { ColDef } from "@/components/datatable";


const useClientTableHeaders = (): ColDef<IClient>[] => {

    const { t } = useTranslation();

    const headers: ColDef<IClient>[] = [
        {
            field: 'full_name',
            headerName: t('components.dataTable.headers.name'),
            width: '25%',
            sortable: true,
            render: (row, i) => <Box display="flex" alignItems="center">
                <Avatar index={i} name={row.full_name} src={row.avatar ?? ''} />
                <Box ml="12px">
                    {capitalize(row.full_name)}
                </Box>
            </Box>
        },
        {
            field: 'user_id',
            headerName: t('components.dataTable.headers.userId'),
            render: (row) => row.user_meta.id ?? '-'
        },
        {
            field: 'client_id',
            headerName: t('components.dataTable.headers.clientId'),
            render: (row) => row.id ?? '-'
        },
        {
            field: 'timezone',
            headerName: t('components.dataTable.headers.timezone'),
            render: (row) => row.user_meta.timezone ?? '-'
        },
        {
            field: 'created_at',
            headerName: t('components.dataTable.headers.created'),
            render: (row) => row.user_meta.created_at ? dayjs(row.user_meta.created_at).format('DD/MM/YYYY') : '-'
        },
        {
            field: 'onboarded_at',
            headerName: t('components.dataTable.headers.onboarded'),
            render: (row) => row.onboarded_at ? dayjs(row.onboarded_at).format('DD/MM/YYYY') : '-'
        },
        {
            field: 'actions',
            headerName: '',
            width: '6%',
            render: (row: IClient) => <ClientMenu row={row} />
        }
    ]

    return headers;
}

export default useClientTableHeaders;