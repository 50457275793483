import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Helpers
// import showToast from "@/_lib/toast";

// Services and interfaces
import { IClient } from "@/interfaces/client/client";

// Components
import { Box } from "@mui/material";
import { Options } from "@/components/menu";
import OverflowMenu from "@/components/overflow_menu";
import useBulkActions from "@/components/datatable/use_bulk_actions";


interface _ClientMenuProps {
    row: IClient;
}

const ClientMenu: FC<_ClientMenuProps> = ({
    row,
}) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { showBulkActions } = useBulkActions();
    const [open, setOpen] = useState<string|null>(null);

    if (open) {null}

    const options: Options[] = [
        {name: t('components.menuItems.edit'), disabled: false, action: () => navigate(`/client/${row.id}/overview`)},
    ]

    // const handleDelete = () => {
        // deleteClient(row.id).unwrap().then(() => {
        //     showToast({
        //         type: 'success', 
        //         title: t('notifications.client.deleted.title', {count: 1}), 
        //         message: <Trans 
        //             i18nKey="notifications.client.deleted.message" 
        //             values={{name: row.full_name}}
        //             components={{bold: <strong />}}
        //         />});
        //     setOpen(null);
        // }).catch((error: ApiError) => {
        //     showToast({type: 'error', apiError: error.type})
        // })
    // }

    return (
        <Box display="flex" justifyContent="flex-end">
            <OverflowMenu 
                position={{horizontal: 'right', vertical: 'bottom'}}
                options={options}
                deleteLabel={t('components.menuItems.delete')}
                disabled={showBulkActions}
                onDelete={() => setOpen('delete')}
                />
            {/* {open === 'delete' && <DeleteClientModal
                open={open === 'delete'}
                onClose={() => setOpen(null)}
                name={`${row.full_name}`}
                isLoading={isDeleting}
                onDelete={handleDelete}
                />} */}
            
        </Box>
    )
}

export default ClientMenu;