import baseApi from '@/repositories/base_api';
import { ICoach } from '@/interfaces/coach/coach';
import { IUserForm } from '@/interfaces/user/user_form';
import { IAPIResponse } from '@/interfaces/api/response';
import { ICoachList } from '@/interfaces/coach/coach_list';
import IPagination, { toQueryString } from '@/interfaces/api/pagination';


export const coachApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getCoaches: build.query<ICoachList, IPagination>({
            query: (pagination) => ({ url: `/coach${toQueryString(pagination)}`, method: 'GET' }),
            providesTags: ['Coach.list'],
        }),
        getCoach: build.query<ICoach, string>({
            query: (id) => ({ url: `/coach/${id}`, method: 'GET' }),
            transformResponse: (response: IAPIResponse<ICoach>) => response.data
        }),
        updateCoach: build.mutation<ICoach, {id: string, data: IUserForm}>({
            query: ({id, data}) => ({ url: `/coach/${id}`, method: 'PATCH', data: data }),
            transformResponse: (response: IAPIResponse<ICoach>) => response.data,
            async onQueryStarted(data, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(coachApi.util.updateQueryData('getCoach' as never, data.id as never, coach => {
                    return response.data;
                }))).catch(() => {});
            },
            invalidatesTags: ['Coach.list']
        }),
        verifyCoach: build.mutation<ICoach, string>({
            query: (id) => ({ url: `/coach/${id}/verify`, method: 'patch' }),
            transformResponse: (response: IAPIResponse<ICoach>) => response.data,
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(coachApi.util.updateQueryData('getCoach' as never, id as never, coach => {
                    return response.data;
                }))).catch(() => {});
            },
            invalidatesTags: ['Coach.list']
        }),
        resendCoachVerification: build.mutation<ICoach, string>({
            query: (id) => ({ url: `/coach/${id}/verify/resend`, method: 'patch' }),
            transformResponse: (response: IAPIResponse<ICoach>) => response.data,
            invalidatesTags: ['Coach.list']
        }),
    }),
})

export const { 
    useGetCoachesQuery,
    useGetCoachQuery,
    useLazyGetCoachQuery,
    useUpdateCoachMutation,
    useVerifyCoachMutation,
    useResendCoachVerificationMutation
} = coachApi;