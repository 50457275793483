import { t } from "i18next";
import { FC, useState } from "react";
import { useFormContext } from "react-hook-form";

// Services and interfaces
import { IChangePasswordForm } from "@/interfaces/auth/change_password";

// Styles
import { ArrowRight, Reset } from "@carbon/icons-react";

// Components
import Button from "@/components/button";
import { Box, Container } from "@mui/material";
import PasswordInput from "@/components/password_input";
import ResetModal from "./reset_modal";


interface _SecurityFormProps {
    resetLoading: boolean;
    updateLoading: boolean;
    onResetPassword: () => void;
    onUpdatePassword: (data: IChangePasswordForm) => void;
}

const SecurityForm: FC<_SecurityFormProps> = ({
    resetLoading,
    updateLoading,
    onResetPassword,
    onUpdatePassword
}) => {

    const [open, setOpen] = useState(false);
    const { control, handleSubmit, formState } = useFormContext<IChangePasswordForm>();

    const handleResetPassword = () => {
        onResetPassword();
        setOpen(false);
    }

    return (
        <Container sx={{py: '24px'}}>

            <Box display="flex" flexDirection="column" justifyContent="flex-start">

                <Box sx={{display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '24px', rowGap: '32px', alignItems: 'start', padding: '0px !important'}}>

                    <Box display="flex" flexDirection="column" flexGrow={1} maxWidth="540px" alignItems="center" justifyContent="center">

                        <PasswordInput
                            name='password'
                            control={control}
                            label={t('inputs.titles.password')} 
                            tabIndex={1}
                            placeHolder={t('inputs.placeholders.passwordNew')}
                            showRequirements
                            />

                    </Box>

                    <Box display="flex" flexDirection="column" flexGrow={1} maxWidth="540px" alignItems="center" justifyContent="center">
                        <PasswordInput
                            name='password_confirmation'
                            control={control}
                            label={t('inputs.titles.passwordConfirmation')}
                            placeHolder={t('inputs.placeholders.passwordNew')}
                            tabIndex={2}
                            />
                    </Box>
                    
                    <Box />

                    {/* Actions */}
                    <Box display="flex" flexGrow={1} height="24px" width="100%" maxWidth="540px" justifyContent="end">
                        <Button
                            label={t('components.buttons.resetPassword')}
                            loading={resetLoading}
                            disabled={updateLoading}
                            endIcon={<Reset />}
                            onClick={() => setOpen(true)}
                            sx={{marginRight: '16px'}}
                            />
                            {open && <ResetModal
                                open={open}
                                onClose={() => setOpen(false)}
                                isLoading={resetLoading}
                                onSubmit={handleResetPassword}
                                />}
                        <Button
                            label={t('components.buttons.setNewPassword')}
                            loading={updateLoading}
                            disabled={!formState.isValid || resetLoading}
                            endIcon={<ArrowRight />}
                            onClick={handleSubmit(onUpdatePassword)}
                        />
                    </Box>

                </Box>

            </Box>
        </Container>
    )
}

export default SecurityForm;