import { FC } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";

// Helpers
import useClientMenuItems from "@/_configs/client_menu";

// Services and interfaces
import { useGetClientQuery } from "@/repositories/client";

// Components
import { Box } from "@mui/material";
import Avatar from "@/components/avatar";
import NavSideMenu from "@/components/navigation/side_menu";


const ClientPage: FC = () => {

    const { id } = useParams()
    const navigate = useNavigate()
    const menuItems = useClientMenuItems(id!);
    const { data: client, isLoading } = useGetClientQuery(id!)

    const back = () => navigate('/client/list')

    return (
        <Box height='calc(100vh - 40px)' display='flex' overflow='hidden'>

            <NavSideMenu 
                kind='ghost'
                onBack={back}
                avatar={
                    <Avatar 
                        size="small"
                        name={client?.full_name ?? ''}
                        src={client?.avatar ?? ''}
                        sx={{mr: 1}}
                    /> 
                }
                label={client?.full_name}
                items={menuItems}
                isLoading={isLoading}
                />
     
            {/* Main content */}
            <Box display='flex' flexDirection='column' width='100%' height='100%'>
                <Box flexGrow={1} overflow='hidden'>
                    <Outlet />
                </Box>
            </Box>

        </Box>
    );
}

export default ClientPage;