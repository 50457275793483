import { t } from "i18next";
import { NavItem } from "@/interfaces/components/nav_item";
import { Dashboard, Events, User } from "@carbon/icons-react";

const useNavigationItems = (): Array<NavItem> => {
    const NavItems: Array<NavItem> = [
        {
            "label": t('navigation.dashboard'),
            "startIcon": <Dashboard />,
            "path": "/dashboard",
        },
        {
            "label": t('navigation.coachManagement'),
            "startIcon": <User />,
            "path": "/coach/list",
            "tabsRoot": "/coach"
        },
        {
            "label": t('navigation.clientManagement'),
            "startIcon": <Events />,
            "path": "/client/list",
            "tabsRoot": "/client"
        }
    ]

    return NavItems;
}

export default useNavigationItems;