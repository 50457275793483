import baseApi from '@/repositories/base_api';
import { IClient } from '@/interfaces/client/client';
import { IUserForm } from '@/interfaces/user/user_form';
import { IAPIResponse } from '@/interfaces/api/response';
import { IClientList } from '@/interfaces/client/client_list';
import IPagination, { toQueryString } from '@/interfaces/api/pagination';


export const clientApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getClients: build.query<IClientList, IPagination>({
            query: (pagination) => ({ url: `/clients${toQueryString(pagination)}`, method: 'GET' }),
            providesTags: ['Client.list'],
        }),
        getClient: build.query<IClient, string>({
            query: (id) => ({ url: `/clients/${id}`, method: 'GET' }),
            transformResponse: (response: IAPIResponse<IClient>) => response.data
        }),
        updateClient: build.mutation<IClient, {id: string, data: IUserForm}>({
            query: ({id, data}) => ({ url: `/clients/${id}`, method: 'PATCH', data: data }),
            transformResponse: (response: IAPIResponse<IClient>) => response.data,
            async onQueryStarted(data, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(clientApi.util.updateQueryData('getClient' as never, data.id as never, client => {
                    return response.data;
                }))).catch(() => {});
            },
            invalidatesTags: ['Client.list']
        }),
        verifyClient: build.mutation<IClient, string>({
            query: (id) => ({ url: `/clients/${id}/verify`, method: 'patch' }),
            transformResponse: (response: IAPIResponse<IClient>) => response.data,
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(clientApi.util.updateQueryData('getClient' as never, id as never, client => {
                    return response.data;
                }))).catch(() => {});
            },
            invalidatesTags: ['Client.list']
        }),
        resetClientOnboarding: build.mutation<IClient, string>({
            query: (id) => ({ url: `/clients/${id}/onboard/reset`, method: 'patch' }),
            transformResponse: (response: IAPIResponse<IClient>) => response.data,
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(clientApi.util.updateQueryData('getClient' as never, id as never, client => {
                    return response.data;
                }))).catch(() => {});
            },
            invalidatesTags: ['Client.list']
        }),
        resendClientOnboarding: build.mutation<IClient, string>({
            query: (id) => ({ url: `/clients/${id}/onboard/resend`, method: 'patch' }),
            transformResponse: (response: IAPIResponse<IClient>) => response.data,
            invalidatesTags: ['Client.list']
        }),
    }),
})

export const { 
    useGetClientsQuery,
    useGetClientQuery,
    useLazyGetClientQuery,
    useUpdateClientMutation,
    useVerifyClientMutation,
    useResetClientOnboardingMutation,
    useResendClientOnboardingMutation,
} = clientApi;