export const ApiErrorEnum = {

    // Auth errors
    auth_invalid_credentials: 'auth_invalid_credentials',
    auth_missing_email: 'auth_missing_email',
    auth_missing_password: 'auth_missing_password',
    auth_password_reset_failed: 'auth_password_reset_failed',

    // Admin errors
    admin_not_found: 'admin_not_found',
    admin_not_authorised: 'admin_not_authorised',
    admin_no_valid_role: 'admin_no_valid_role',

    // Verification errors
    verification_not_verified: 'verification_not_verified',
    verification_already_verified: 'verification_already_verified',
    verification_url_expired: 'verification_url_expired',
    verification_invalid_signature: 'verification_invalid_signature',

    // User errors
    user_not_found: 'user_not_found',
    user_create_failed: 'user_create_failed',
    user_update_failed: 'user_update_failed',
    user_suspended: 'user_suspended',
    user_not_active: 'user_not_active',
    user_not_coach: 'user_not_coach',

    // Email errors
    email_already_registered: 'email_already_registered',

    // Coach errors
    coach_not_found: 'coach_not_found',
    coach_create_failed: 'coach_create_failed',
    coach_update_failed: 'coach_update_failed',
    coach_settings_update_failed: 'coach_settings_update_failed',


    // Team errors
    team_not_found: 'team_not_found',
    team_already_exists: 'team_already_exists',
    team_create_failed: 'team_create_failed',
    team_update_failed: 'team_update_failed',
    team_delete_failed: 'team_delete_failed',
    team_owner_not_found: 'team_owner_not_found',

    // Client errors
    client_not_found: 'client_not_found',
    client_not_authorised: 'client_not_authorised',
    client_already_setup: 'client_already_setup',
    client_already_exists: 'client_already_exists',
    client_update_failed: 'client_update_failed',
    client_delete_failed: 'client_delete_failed',

    // Exercise errors
    exercise_not_found: 'exercise_not_found',
    exercise_not_authorised: 'exercise_not_authorised',
    exercise_create_failed: 'exercise_create_failed',
    exercise_update_failed: 'exercise_update_failed',
    exercise_delete_failed: 'exercise_delete_failed',

    // Supplement errors
    supplement_not_found: 'supplement_not_found',
    supplement_not_authorised: 'supplement_not_authorised',
    supplement_create_failed: 'supplement_create_failed',
    supplement_update_failed: 'supplement_update_failed',
    supplement_delete_failed: 'supplement_delete_failed',

    // Food errors
    food_not_found: 'food_not_found',
    food_not_authorised: 'food_not_authorised',
    food_create_failed: 'food_create_failed',
    food_update_failed: 'food_update_failed',
    food_delete_failed: 'food_delete_failed',

    // Validation errors
    validation_failed: 'validation_failed',
    validation_value_missing: 'validation_value_missing',

    // Notification errors
    notification_failed: 'notification_failed',

    // Token errors
    token_not_found: 'token_not_found',
    token_not_valid: 'token_not_valid',
    token_delete_failed: 'token_delete_failed',

    // Device id errors
    device_id_not_found: 'device_id_not_found',
    device_id_delete_failed: 'device_id_delete_failed',

    // Generic errors
    maintenance: 'maintenance',
    unauthorized: 'unauthorized',
    invalid_method: 'invalid_method',
    invalid_property: 'invalid_property',
    invalid_enum: 'invalid_enum',
    invalid_link: 'invalid_link',
    timeout: 'timeout',
    unexpected_error: 'unexpected_error',

    // Realm errors
    realm_not_authorised: 'realm_not_authorised',
    realm_user_delete_failed: 'realm_user_delete_failed',

} as const
  
export type ApiErrorEnum = typeof ApiErrorEnum[keyof typeof ApiErrorEnum]