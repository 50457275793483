import { Navigate } from "react-router-dom";
import CoachList from "@/pages/coach/coach_list";
import CoachPage from "@/pages/coach/coach_page";
import CoachOverview from "@/pages/coach/sub_pages/overview";
import CoachSecurity from "@/pages/coach/sub_pages/security";


const coachRoutes = {
    path: "coach",
    children: [
        {
        index: true,
        element: <Navigate to="/coach/list" replace />,
        },
        {
        path: "list",
        element: <CoachList />
        },
        {
        path: ":id",
        element: <CoachPage />,
        children: [
            {
            index: true,
            element: <Navigate to="/coach/:id/overview" replace />,
            },
            {
            path: "overview",
            element: <CoachOverview />
            },
            {
            path: "security",
            element: <CoachSecurity />
            },
        ]
        }
    ]
};

export default coachRoutes;