import { FC } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";

// Helper
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { useGetCoachQuery } from "@/repositories/coach";
import { IChangePasswordForm, passwordSchema } from "@/interfaces/auth/change_password";
import { useResetPasswordMutation, useUpdatePasswordMutation } from "@/repositories/user";

// Components
import { Box } from "@mui/material";
import SecondaryToolbar from "@/components/secondary_toolbar";
import SecurityForm from "@/pages/common/reset_password_form";


const CoachSecurity: FC = () => {

    const { id } = useParams();
    const { t } = useTranslation();
    const { data: coach } = useGetCoachQuery(id!);
    const [resetPassword, { isLoading: resetLoading }] = useResetPasswordMutation();
    const [updatePassword, { isLoading: updateLoading }] = useUpdatePasswordMutation();

    const formMethods = useForm<IChangePasswordForm>({
        resolver: zodResolver(passwordSchema),
        mode: 'onBlur',
        defaultValues: {
            password: '',
            password_confirmation: ''
        }
    });

    const handleResetPassword = () => {
        const user_id = String(coach?.user_meta.id);
        if (!user_id) return;
        resetPassword(user_id).unwrap().then((data) => {
            navigator.clipboard.writeText(data.password)
            showToast({
                type: 'success', 
                title: t('notifications.user.passwordReset.title'), 
                message: t('notifications.user.passwordReset.message', {email: coach?.user_meta.email})
            });
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type});
        });
    }

    const submitForm = async (data: IChangePasswordForm) => {
        const user_id = String(coach?.user_meta.id);
        if (!user_id) return;
        updatePassword({id: user_id, data}).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.user.passwordUpdated.title'), 
                message: t('notifications.user.passwordUpdated.message', {name: coach?.full_name})
            });
            formMethods.reset();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type});
        });
    }

    return (
        <Box>
            <SecondaryToolbar
                kind="primary"
                title={t('pages.coach.headers.overview')}
                />

            <FormProvider {...formMethods}>
                <SecurityForm
                    resetLoading={resetLoading}
                    updateLoading={updateLoading}
                    onResetPassword={handleResetPassword}
                    onUpdatePassword={submitForm}
                    />
            </FormProvider>

        </Box>
    )
}

export default CoachSecurity;


