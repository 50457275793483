import { t } from "i18next";
import { FC, useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { IUserForm, userFormSchema } from "@/interfaces/user/user_form";
import { useLazyGetCoachQuery, useResendCoachVerificationMutation, useUpdateCoachMutation, useVerifyCoachMutation } from "@/repositories/coach";

// Components
import { TagProps } from "@/components/tag";
import { Box, Container } from "@mui/material";
import UserForm from "@/pages/common/user_form";
import SecondaryToolbar from "@/components/secondary_toolbar";


const CoachOverview: FC = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const [getCoach, { data: coach }] = useLazyGetCoachQuery();
    const [update, { isLoading: updateLoading }] = useUpdateCoachMutation();
    const [verify, { isLoading: verifyLoading }] = useVerifyCoachMutation();
    const [resend, { isLoading: resendLoading }] = useResendCoachVerificationMutation();

    const isVerified = coach?.user_meta.email_verified_at != null;
    const tags: TagProps[] = [
        { label: t('components.tag.labels.verified', {context: `${isVerified}`}), outlined: true, colour: isVerified ? 'teal' : 'red' }
    ];
    
    const formMethods = useForm<IUserForm>({
        resolver: zodResolver(userFormSchema),
        mode: 'onBlur',
        defaultValues: {
            first_name: coach?.user_meta.first_name ?? '',
            last_name: coach?.user_meta.last_name ?? '',
            mobile: coach?.user_meta.mobile ?? '',
            email: coach?.user_meta.email ?? '',
        }
    });

    useEffect(() => {
        if (id) {
            getCoach(id).unwrap().then((c) => {
                formMethods.reset({
                    first_name: c.user_meta.first_name,
                    last_name: c.user_meta.last_name,
                    mobile: c.user_meta.mobile,
                    email: c.user_meta.email,
                })
            }).catch((error: ApiError) => {
                navigate(`/coach/list`);
                showToast({type: 'error', apiError: error.type})
            });
        }
    }, [id, formMethods, getCoach, navigate,])

    const handleUpdate = (data: IUserForm) => {
        if (!id) return;
        update({id, data}).unwrap().then((coach) => {
            showToast({
                type: 'success', 
                title: t('notifications.coach.updated.title'), 
                message: t('notifications.coach.updated.message', {name: coach.full_name})
            });
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    const handleVerify = () => {
        if (!id) return;
        verify(id).unwrap().then((coach) => {
            showToast({
                type: 'success', 
                title: t('notifications.coach.verified.title'), 
                message: t('notifications.coach.verified.message', {name: coach.full_name})
            });
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    const handleResend = () => {
        if (!id) return;
        resend(id).unwrap().then((coach) => {
            showToast({
                type: 'success', 
                title: t('notifications.coach.resentVerification.title'), 
                message: t('notifications.coach.resentVerification.message', {name: coach.full_name})
            });
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    return (
        <Box width='100%' height='100%'>

            <SecondaryToolbar
                kind="primary"
                title={t('pages.coach.headers.overview')}
                tags={tags}
                action1={!isVerified ? {
                    label: t('components.buttons.resendVerification'),
                    loading: verifyLoading,
                    disabled: resendLoading,
                    onClick: handleResend,
                } : undefined}
                action2={!isVerified ? {
                    label: t('components.buttons.verify'),
                    loading: resendLoading,
                    disabled: verifyLoading,
                    onClick: handleVerify,
                } : undefined}
            />

            {/* Main content */}
            <Box sx={{overflowY: 'auto', height: 'calc(100% - 48px)'}}>
                <Container sx={{py: '24px'}}>

                    <FormProvider {...formMethods}>
                        <UserForm
                            isLoading={updateLoading}
                            onSubmit={formMethods.handleSubmit(handleUpdate)}
                            />
                    </FormProvider>

                </Container>
            </Box>
            
        </Box>
    )

}

export default CoachOverview;