import { t } from "i18next";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

// Services and interfaces
import IPagination from "@/interfaces/api/pagination";
import { useGetCoachesQuery } from "@/repositories/coach";

// Components
import { Box, Container } from "@mui/material";
import DataTable from "@/components/datatable";
import useCoachTableHeaders from "./coach_headers";
import SecondaryToolbar from "@/components/secondary_toolbar";


const CoachList: FC = () => {

    const navigate = useNavigate();
    const headers = useCoachTableHeaders();
    const [pagination, setPagination] = useState<IPagination>({page: 1, limit: 10});
    const { data: list, isLoading } = useGetCoachesQuery(pagination);

    const handleRowClick = (row: any) => {
        navigate(`/coach/${row.id}/overview`);
    }

    return (
        <Box display="flex" flexDirection="column" height="calc(100vh - 40px)" sx={{overflow: 'hidden'}}>

            <SecondaryToolbar 
                title={t('pages.coach.pageTitle')}
                />

                <Container sx={{padding: '24px 0', overflowY: 'auto'}}>

                    <DataTable 
                        data={list?.data}
                        columns={headers}
                        hideHeader
                        filterKey="full_name"
                        isLoading={isLoading}
                        rowClick={handleRowClick}
                        noDataFoundMessage={''}
                        localPagination={false}
                        paginationData={list?.metadata}
                        onChange={setPagination}
                        />
                        
                </Container>

        </Box>
    );
}

export default CoachList;