import { z, ZodTypeAny } from "zod"
import { t } from "i18next";
import { isValidPhoneNumber } from 'react-phone-number-input';


const decimalPlaces = (num: number, places: number): boolean => {
    const regex = new RegExp(`^-?\\d+(\\.\\d{0,${places}})?$`);
    return regex.test(num.toString());
};

export const decimalRule = (places: number, allowZero: boolean = true) => {
    return z.intersection(
        z.number({message: t('inputs.errors.required')}).refine((num) => num !== undefined && num !== null && (allowZero ? num >= 0 : num > 0), {
            message: t('inputs.errors.required'),
        }),
        z.number().refine((num) => decimalPlaces(num, places), {
            message: t('inputs.errors.decimalRequired', {places: places, plural: places > 1 ? 's' : ''}),
        }),
    );
};

export const transformEmptyStringToNull = (rule: ZodTypeAny)=> {
     return z.preprocess((value) => value === '' ? null : value, rule);
}

export const transformEmptyStringToNullAndNonZero = (rule: ZodTypeAny, message?: string) => {
    return rule
      .transform((val) => (val === '' ? null : val))
      .refine((val) => val === null || val > 0, {
        message: t(message ?? 'inputs.errors.valueNotZero'),
      });
  };

export default {
    
    required: z.string().trim().min(1, t('inputs.errors.required')),
    stringOptional: z.string().trim().nullable().optional(),
    requiredNumber: z.union([z.string().transform((value) => (value == '' ? undefined : Number(value))), z.number()])
        .refine((value) => value !== undefined && value > 0, {message: t('inputs.errors.required')}),
    numberOptional: z.union([z.string(), z.number()]).transform(val => val === '' ? null : Number(val)).nullable().optional(),
    checkinDays: z.number().int().array().nonempty(t('inputs.errors.checkinDays')),
    numberArray: z.number().int().array().nonempty(),
    checkbox: z.boolean().refine((val) => val === true, {
        //TODO move to translations
        message: "Please read and accept the terms and conditions",
      }),
    email: z
        .string()
        .min(1, t('inputs.errors.emailRequired'))
        .email(t('inputs.errors.invalidEmail')),
    passwordRequired: z.string().min(1, t('inputs.errors.passwordRequired')),
    passwordLength: z.string().min(8, t('inputs.errors.passwordLength')),
    // atleast 1 uppercase character
    passwordUppercase: z.string().regex(/[A-Z]/, t('inputs.errors.passwordUppercase')),
    //at least 1 number
    passwordNumber: z.string().regex(/[0-9]/, t('inputs.errors.passwordNumber')),
    //at least 1 special character
    passwordSpecial: z.string().regex(/[!@£$%^&*",.?_]+$/, t('inputs.errors.passwordSpecial')),
    password: z
        .string()
        .min(1, t('inputs.errors.passwordRequired'))
        .min(8, t('inputs.errors.passwordLength')),
    passwordConfirmation: z
        .string()
        .min(1, t('inputs.errors.passwordRequired')),
    passwordCriteria: z
        .string()
        .min(1, t('inputs.errors.passwordRequired'))
        .min(8, t('inputs.errors.passwordCriteria'))
        .regex(/[A-Z]/, t('inputs.errors.passwordCriteria'))
        .regex(/[0-9]/, t('inputs.errors.passwordCriteria'))
        .regex(/[!@£$%^&*",.?_]/, t('inputs.errors.passwordCriteria')),
    name: z.string().min(1, t('inputs.errors.required')),
    phoneNumber: z
        .string()
        .min(1, t('inputs.errors.required'))
        .refine((value) => isValidPhoneNumber(value), {
        message: t('inputs.errors.invalidPhoneNumber')
    }),
    phoneNumberOptional: z
        .string()
        .optional()
        .refine((value) => !value || isValidPhoneNumber(value), {
        message: t('inputs.errors.invalidPhoneNumber')
        }),
    accessCode: z
        .string()
        .min(1, t('inputs.errors.accessCodeRequired'))
        .min(7, t('inputs.errors.accessCodeLength')),
    file: z
        .instanceof(File),
    video: z
        .string()
        .optional()
        .superRefine(async (val, ctx) => {
            if (!val || val == "") return;
            const blob = await fetch(val).then(r => r.blob());
            
            if (blob.size > 262144000) { // gt 250mb                
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ['media_link'],
                    message: t('inputs.errors.filesizeTooBig'),
                });
            }

            if (!blob.type.startsWith('video/')) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ['media_link'],
                    message: "video not found" // t('inputs.errors.fileNotVideo'),
                });
            }
        }),   
    blob: z
        .instanceof(Blob),
    blobUrl: z
        .string()
        .url()
        .min(1, t('inputs.errors.required')),
    uuid: z.string().uuid(),
    uuidOptional: z.string().uuid().nullable().optional(),
    boolean: z.boolean(),
    booleanOptional: z.boolean().optional(),
    select: z.string().min(1, t('inputs.errors.required')),
    selectNumber: z.number().min(1, t('inputs.errors.required')),
    selectOptional: z.string().nullable().optional(),
    selectNumberOptional: z.number().nullable().optional(),
    selectObject: z.object({}, {message: t('inputs.errors.required')}).passthrough(),
    selectObjectOptional: z.object({}).passthrough().nullable(),
    multiselect: z.array(z.string()).min(1, t('inputs.errors.required')),
    multiselectOptional: z.array(z.string()).nullable(),
    multiselectObject: z.array(z.object({}, {message: t('inputs.errors.required')}).passthrough()).min(1, t('inputs.errors.required')),
    multiselectObjectOptional: z.array(z.object({}).passthrough()).nullable(),
    teamName: z.string()
        .min(1, t('inputs.errors.teamNameRequired'))
        .max(50, t('inputs.errors.teamNameLength', {length: 50})),
    loomLink: z.string().regex(/^https:\/\/(www\.)?loom\.com\/share\/[a-zA-Z0-9]+(\?.*)?$/, {message: t('inputs.errors.loomLink')}).optional(),
    screencastLink: z.string().regex(/^(https:\/\/)?(www\.)?screencast\.com\/t\/[a-zA-Z0-9]+$/, {message: t('inputs.errors.screencastLink')}).optional(),
    somupLink: z.string().regex(/^https:\/\/somup\.com\/[a-zA-Z0-9]+$/,{ message: t('inputs.errors.screencastLink') }),
    screenPalLink: z.string().regex(/^https:\/\/go\.screenpal\.com(\/watch\/[a-zA-Z0-9]+)?$/, { message: t('inputs.errors.screencastLink') }),
    videolink: z.string().regex(/^(https?:\/\/|www\.)[^\s]*\.(youtube|youtu\.be|vimeo)\.com/i,{message: t('inputs.errors.videoLink')}),
    youTubeLink1: z.string().regex(/^(https:\/\/)?(www\.)?(youtube\.com\/watch\?v=)([A-Za-z0-9\_-]){11}(\/)?$/, {message: t('inputs.errors.youTubeLink')}).optional(),
    youTubeLink2: z.string().regex(/^https:\/\/youtu\.be\/[A-Za-z0-9_-]{11}(\?si=[A-Za-z0-9_-]+)?$/, {message: t('inputs.errors.youTubeLink')}).optional(),
    vimeoLink: z.string().regex(/^(https:\/\/(www\.)?vimeo\.com\/(channels\/[A-Za-z0-9_-]+\/)?[0-9]{9})?$/, {message: t('inputs.errors.vimeoLink')}).optional(),
    link: z.string().url({ message: t('inputs.errors.invalidLink') }),
    linkOptional: z.union([
        z.string().refine((val) => RegExp(/^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+(\/[^\s]*)?$/).test(val),
            { message: t('inputs.errors.invalidLink') }),
        z.literal(''),
        z.undefined(),
      ]).optional(),

    date: z.coerce.date(),
    dateOptional: z.coerce.date().optional(),
    epochDate: z.number()
        .int() // Ensures the value is an integer
        .positive() // Ensures the value is positive
        .refine(value => {
            const date = new Date(value * 1000); // Convert epoch seconds to milliseconds
            return date.getTime() > 0 && !isNaN(date.getTime()); // Check if the date is valid
        }, {
            message: "Invalid epoch timestamp",
        }),
    mongoDateOptional: z.object({
        $date: z.object({
          $numberLong: z.string().refine((value) => {
            const timestamp = parseInt(value, 10);
            const date = new Date(timestamp);
      
            return !isNaN(timestamp) && date.getTime() > 0;
          }, {
            message: "Invalid MongoDB epoch timestamp",
          }),
        }),
      }).nullable().optional(),
    // Form Builder
    questionType: z.enum(['text', 'long_text', 'select', 'multiselect', 'rating', 'email', 'phone'], {message: t('inputs.errors.invalidQuestionType')}),
    // Nutrition Builder
    mealName: z.string().trim().min(1, t('inputs.errors.required')).max(40, t('inputs.errors.maxLength', {length: 40})),
}