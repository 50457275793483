import { FC } from "react";
import { t } from "i18next";

// Components
import Modal from "@/components/modal/modal";


interface _ResetModal {
    open: boolean;
    onClose: () => void;
    isLoading: boolean;
    onSubmit: () => void;
}

const ResetModal: FC<_ResetModal> = ({
    open,
    onClose,
    isLoading = false,
    onSubmit,
}) => {

    return (
        <Modal 
            open={open}
            onClose={onClose}
            title={t('modals.resetPassword.title')}
            text={t('modals.resetPassword.text')}
            action1={{
                label: t('components.buttons.reset'),
                loading: isLoading,
                onClick: onSubmit,
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isLoading,
                onClick: onClose
            }}
            sx={{'& .MuiPaper-root': {maxWidth: '480px'}}}
            />
    )
}

export default ResetModal;