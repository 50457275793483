import ErrorPage from "@/pages/error";
import DefaultLayout from "@/layouts/default";
import AuthGuard from "@/middleware/auth_guard";
import { createBrowserRouter, Navigate } from "react-router-dom";

// Routes
import { authRoutes } from "./auth_routes";
import coachRoutes from "./coach_routes";
import clientRoutes from "./client_routes";


const router = createBrowserRouter([
  {  
    element: <AuthGuard />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <DefaultLayout />,
        children: [
          {
            index: true,
            element: <Navigate to="/dashboard" replace />,
          },
          {
            path: "dashboard",
            element: <div>Dashboard</div>
          },
          coachRoutes,
          clientRoutes
        ],
      },
    ]
  },
  authRoutes
]);

export default router;