import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom"

// Repositories
import baseApi from "@/repositories/base_api";
import { useGetUserQuery } from "@/repositories/auth";

// Selectors
import { selectIsAuthenticated } from "@/store/auth";

// Components
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";


const AuthGuard = () => {

  const dispatch = useDispatch();
  const { isLoading: userIsLoading } = useGetUserQuery();

  const loaders = [userIsLoading]
  const isLoading = loaders.some(l => l);

  const isAuthenticated = useSelector(selectIsAuthenticated);

  useEffect(() => {
    return () => {
      dispatch(baseApi.util.resetApiState());
    } 
  }, [])

  if (isLoading) {
    return (
      <Backdrop open={true}>
        <CircularProgress />
      </Backdrop>
    ) 
  }
  
  if (!isAuthenticated) {
    return <Navigate to="/auth/login" replace />
  }

  return (
    <Outlet />
  );
};

export default AuthGuard;