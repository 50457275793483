import { Outlet } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

// Styles
import styles from './auth.module.scss';
import logo from '@/assets/logos/1fit_logo_mini_teal.png';

export default function AuthLayout() {
    const { t } = useTranslation();
    return (
        <div className={styles.container}>

            <div className={styles.container__body}>

                {/* Login Form */}
                <div className={`${styles.column}`}>
                    <Outlet />
                </div>

                {/* Brand Column */}
                <div className={`${styles.column} ${styles.column__right}`}>
                    <img src={logo} alt="1fit-logo" height={100}/>
                    <Box height={50} />
                    <h5 className="heading-05 text-on-color">{t('layouts.auth.brandText')}</h5>
                </div>

            </div>

        </div>
    )
}