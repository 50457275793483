import baseApi from '@/repositories/base_api';
import { IAPIResponse } from '@/interfaces/api/response';
import { IUserResponse } from '@/interfaces/user/user_response';
import { IChangePasswordForm } from '@/interfaces/auth/change_password';


export const userApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getUser: build.query<IUserResponse, void>({
            query: () => ({ url: '/user', method: 'GET' }),
            transformResponse: (response: IAPIResponse<IUserResponse>) => response.data,
        }),
        resetPassword: build.mutation<{password: string}, string>({
            query: (id: string) => ({ url: `/user/${id}/password/reset`, method: 'patch',}),
            transformResponse: (response: IAPIResponse<{password: string}>) => response.data
        }),
        updatePassword: build.mutation<void, {id: string, data: IChangePasswordForm}>({
            query: ({id, data}) => ({ url: `/user/${id}/password/update`, method: 'patch', data: data}),
        })
    }),
})

export const { 
    useGetUserQuery,
    useResetPasswordMutation,
    useUpdatePasswordMutation
} = userApi;